<template>
    <div class="max-w-4xl mx-auto bg-white p-4 rounded">
        <div class="flex justify-between">
              <div>
                <!-- <Select v-model="type" class="py-2 focus:outline-none border p-2" @change="onChange">
                    <option value="0" selected>Patient Feedback</option>
                    <option value="1">Mentor Feedback</option>
                </Select> -->
            </div>
        </div>
        <div class="w-full grid grid-cols-12 gap-4">
            <div class="col-span-full md:col-span-6 gap-2  border p-4 rounded-md">
              <div class="p-2">
                <span class="text-lg text-brand">Patient Feedback</span>
              </div>
              <div class="text-left text-lg border p-2 shadow-md rounded-md mb-2">
                <div>Patient Name : {{ feedbacks.patient_name }}</div>
                <div>Patient Phone : {{ feedbacks.patient_phone }}</div>
                <div>Support Type : {{ feedbacks.type ? feedbacks.type.name : ''  }}</div>
                <div>Patient Email : {{ feedbacks.patient_email  }}</div>
                <div>Patient Gender : {{ feedbacks.patient_gender == 1 ? 'Male' : feedbacks.patient_gender == 2 ? 'Female':''   }}</div>
              </div>
              <div class="p-4 border shadow-md rounded-md">
                 <div v-for="(question, index) in patientQuestion" :key="index" class="gap-4">
                   <div class="text-left md:text-xl">{{  question }} </div>
                   <div class="md:mt-2 md:mb-2">
                     <Rating v-model="rating[index]" :read-only="true"/>
                   </div>
                 </div>
              </div>
            </div>
            <div class="col-span-full md:col-span-6 border p-4 rounded-md">
               <div class="p-2">
                <span class="text-lg text-brand">Mentor Feedback</span>
              </div>
             <div class="text-left text-lg border p-2 shadow-md rounded-md mb-2">
                <div>Mentor Name : {{ feedbacks.mentor_name }}</div>
                <div>Mentor Phone : {{ feedbacks.mentor_phone }}</div>
                <div>Mentor Type : {{ feedbacks.type ? feedbacks.type.name : ''  }}</div>
                <div>Mentor Email : {{ feedbacks.mentor_email  }}</div>
                <div>Mentor Gender : {{ feedbacks.mentor_gender == 1 ? 'Male' : feedbacks.patient_gender == 2 ? 'Female':''   }}</div>
              </div>
              <div>
                <div class="p-4 border shadow-md rounded-md">
                 <div v-for="(question, index) in mentorQuestion" :key="index" class="gap-4">
                   <div class="text-left md:text-xl">{{  question }} </div>
                   <div class="md:mt-2 md:mb-2">
                     <Rating v-model="mentorRating[index]" :read-only="true"/>
                   </div>
                 </div>
              </div>
              </div>
            </div>
        </div>
    </div>



     
</template>

<script>
import Select from '../../../../../components/select.vue'
import Input from '../../../../../components/input-text.vue'
import Textarea from '../../../../../components/input-text-area.vue'
import Btn from '../../../../../components/btn.vue'
import Rating from "./Rating.vue"
export default {
    components:{
        Select,
        Input,
        Textarea,
        Btn,
        Rating,
    },
     data() {
        return {
            rating: [],
            mentorRating: [],
            patientQuestion: [],
            mentorQuestion: [],
            type:0,
        }
    },

    created(){
        this.$store.dispatch('adminAppointment/getAppointmentFeedback', {
            id:this.appointmentId
        });
    },
     watch: {    
        '$store.state.adminAppointment.appointment_feedback'( data ){
          console.log('dd',data);
         
            if( data ) {   
               if(data.patient_feedbacks_question instanceof Array){
                let a = -1;
                for(const key in data.patient_feedbacks_ratings) {
                  const b = ++a;
                  this.rating[key] = data.patient_feedbacks_ratings[key];
                  this.patientQuestion[key] = data.patient_feedbacks_question[b];
                }
               } 
               else {
                   this.rating[0] = data.patient_feedbacks_ratings
               }
            };
            if(data.mentor_feedbacks_question instanceof Array){
                let a = -1;
                for(const key in data.mentor_feedbacks_question) {
                  const b = ++a;
                  this.mentorRating[key] = data.mentor_feedbacks_ratings[key];
                  this.mentorQuestion[key] = data.mentor_feedbacks_question[b];
                }
            }
        }
    },

    computed: {
        feedbacks(){
             return this.$store.state.adminAppointment.appointment_feedback;
        },
        feedbackQuestion() {
            const fbquestions = this.$store.state.adminFeedback.feedbacks;
            return fbquestions.questions ? Object.values(fbquestions.questions.questions) : [];
        },
        appointmentId(){
            return this.$route.params.appointmentId;
        }
    },
    methods:{
        // onChange(){
        //     this.$store.dispatch('adminFeedback/getAppointmentFeedback', {
        //         data:this.type
        //     });
        // }
    }
}
</script>